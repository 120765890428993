import React from "react"

import Layout from "components/layout"
import PasswordLinkModal from "components/password-link-modal"

export default () => (
  <Layout title="Password Reset" hideContent={true}>
    <PasswordLinkModal fullScreen />
  </Layout>
)
