import React from "react"

import { Form, Formik } from "formik"
import { navigate } from "gatsby"
import * as Yup from "yup"

import Button from "@kiwicom/orbit-components/lib/Button"
import Illustration from "@kiwicom/orbit-components/lib/Illustration"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"
import Email from "@kiwicom/orbit-components/lib/icons/Email"

import { parseErrorMessage } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import ValidatedInputField from "components/validated-input-field"

import { fetchApi } from "utils/fetch-utils"

const apiBaseUrl = process.env.GATSBY_API_BASE_URL

type SendPasswordResetModalType = {
  handleClose?: () => void
  fullScreen: boolean
}

const SendPasswordResetModal = ({
  handleClose,
  fullScreen,
}: SendPasswordResetModalType) => {
  return (
    <EmberModal
      size="small"
      onClose={handleClose}
      isMobileFullPage={fullScreen}
      fullScreen
    >
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Your email address is not valid")
            .required("Please enter your email address"),
        })}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          const { email } = values
          setStatus(null)
          fetchApi(`${apiBaseUrl}/v1/login/`, "POST", {
            email,
            prompt_for_password: true,
          }).subscribe((response) => {
            if (response && !response.error) {
              setStatus({
                success: true,
                email: email,
              })
            } else {
              setStatus({
                error: parseErrorMessage(response),
              })
            }
            setSubmitting(false)
          })
        }}
      >
        {(props) => (
          <>
            <EmberModalHeader
              title="Reset your Ember password"
              illustration={<Illustration name="Mailbox" size="small" />}
              description={
                props.status?.success ? (
                  <Text size="large" spaceAfter="largest">
                    We've sent a password reset link to {props.status?.email}.
                    Click the link and you'll be able to set a new password.
                  </Text>
                ) : props.status?.error ? (
                  <Text size="large" spaceAfter="largest">
                    Failed to send a password reset link. The error message was
                    "{props.status.error}".
                  </Text>
                ) : (
                  <Text size="large">
                    Enter your email and we'll send you a link to reset your
                    password.
                  </Text>
                )
              }
            />
            {props.status == null && (
              <EmberModalSection>
                <Stack spacing="large">
                  <Form>
                    <Stack spacing="large">
                      <ValidatedInputField
                        placeholder="name@email.com"
                        prefix={<Email />}
                        name="email"
                      />

                      <Button
                        disabled={props.isSubmitting}
                        loading={props.isSubmitting}
                        fullWidth={true}
                        submit={true}
                      >
                        Send me a password reset link
                      </Button>
                    </Stack>
                  </Form>
                  <TextLink
                    type="secondary"
                    onClick={() => {
                      navigate("/login")
                    }}
                  >
                    Log in instead
                  </TextLink>
                </Stack>
              </EmberModalSection>
            )}
          </>
        )}
      </Formik>
    </EmberModal>
  )
}

export default SendPasswordResetModal
